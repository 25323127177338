import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ["tssize","fthigh", "ftlow"]

    connect() {
        if ( $("#heat_pump_enquiry_flow_temp").val() == "High" ) {
            this.fthighTarget.classList.add("active")
            this.ftlowTarget.classList.remove("active")
        } else {
            this.ftlowTarget.classList.add("active")
            this.fthighTarget.classList.remove("active")
        }
    }

    set_high(event) {
        event.preventDefault()
        event.stopPropagation()
        $("#heat_pump_enquiry_flow_temp").val("High")
        $(".ft-lnk").removeClass("active")
        this.fthighTarget.classList.add("active")
    }

    set_low(event) {
        event.preventDefault()
        event.stopPropagation()
        $("#heat_pump_enquiry_flow_temp").val("Low")
        $(".ft-lnk").removeClass("active")
        this.ftlowTarget.classList.add("active")
    }

}
