import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ["tssize","tsno", "tsyes"]
    static values = { index: Number }

    connect() {
        if ( $("#heat_pump_enquiry_thermal_store").val() == "false" ) {
            this.tsnoTarget.classList.add("active")
            this.tssizeTarget.style.display = "none"
        } else {
            this.tsyesTarget.classList.add("active")
            this.tssizeTarget.style.display = "block"
        }
    }

    alert(event) {

    }

    enable_ts(event) {
        event.preventDefault()
        event.stopPropagation()

        $("#heat_pump_enquiry_thermal_store").val("true")
        $(".ts-lnk").removeClass("active")
        this.tsyesTarget.classList.add("active")
        this.tssizeTarget.style.display = "block"
    }

    disable_ts(event) {
        event.preventDefault()
        event.stopPropagation()
        $("#heat_pump_enquiry_thermal_store").val("false")
        $(".ts-lnk").removeClass("active")
        this.tsnoTarget.classList.add("active")
        this.tssizeTarget.style.display = "none"
    }

}
