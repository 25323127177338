import { Controller } from "stimulus"

export default class extends Controller {

    static values = { index: Number }

    alert() {
        alert(this.indexValue)
    }

    set_size() {
        $("#heat_pump_enquiry_heating_system_profile_id").val(this.indexValue)

    }


}
