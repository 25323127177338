import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ "buildicon" ]
    static values = { index: Number }

    initialize() {
        this.origID = $("#heat_pump_enquiry_building_type_id").val()
        if (this.origID === this.indexValue) {
            this.element.classList.add("active")
        }

    }

    set_building_active() {
        $(".building").removeClass("active")
        this.element.classList.add("active")
        $("#heat_pump_enquiry_building_type_id").val(this.indexValue)
    }


}
